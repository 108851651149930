*,
*::after,
*::before {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  font-size: 62.5%;
  scroll-behavior: smooth;
}

body {
  font-family: "Open Sans", sans-serif;
  font-size: 1.6rem;
  background-color: #fff;
}

[id] {
  scroll-margin-top: 3em;
}

.wrapper {
  margin: 0 auto;
  max-width: 1200px;
  width: 100%;
}

.section-padding {
  padding: 2em 1em;
}

.section-padding-hero {
  padding: 2em 0em;
}

.section-margin__top {
  margin-top: 2em;
}
.section-margin__bottom {
  margin-bottom: 1em;
}

.section-heading {
  position: relative;
  padding-left: 0.5em;
  font-size: 2.5rem;
  font-weight: lighter;
  text-transform: uppercase;
  text-align: left;
  letter-spacing: 2px;
}
.section-heading span {
  color: #006be5;
  font-weight: normal;
}
.section-heading::before {
  position: absolute;
  width: 5px;
  height: 100%;
  top: 50%;
  left: 0;
  transform: translate(0, -50%);
  background-color: #006be5;
  content: "";
}

.section-title {
  margin: 1em 0;
  font-size: 2.3rem;
  font-weight: lighter;
}
.section-title span {
  color: #006be5;
  font-weight: normal;
}
.section-title--small {
  font-size: 2rem;
}

.section-header__padding {
  padding: 2em 1em 0em 1em;
}
.section-header__title {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  font-size: 3rem;
  font-weight: lighter;
  z-index: 800;
}
.section-header__title span {
  color: #006be5;
  font-weight: normal;
}

.section-text {
  font-size: 1.6rem;
  padding: 0.5em 0;
}
.section-text span {
  color: #006be5;
  font-weight: 600;
}
.section-text--phone {
  font-size: 1.8rem;
}

.arrow {
  padding: 0.2em 0.5em;
  font-size: 1.8em;
  border: none;
  cursor: pointer;
}

.btn {
  display: flex;
  justify-content: center;
  padding: 0.5em;
  background-color: #fff;
  border: 1px solid #006be5;
  border-radius: 10px;
  text-decoration: none;
  font-weight: normal;
  font-size: 1.6rem;
  color: #232323;
  cursor: pointer;
}
.btn span {
  color: #006be5;
  margin-left: 0.3em;
}
.btn::before {
  background-color: #eee;
}
.btn__animation {
  position: relative;
  transition: background-color 0.3s;
  overflow: hidden;
  z-index: 0;
}
.btn__animation::before {
  display: block;
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transform: scaleX(0);
  transform-origin: right;
  transition: transform 0.3s;
  z-index: -1;
}
.btn__animation:hover::before {
  transform: scaleX(1);
  transform-origin: left;
}

.slider {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.slider__box {
  position: relative;
  display: flex;
  height: 100%;
}

.nav {
  position: sticky;
  top: 0;
  height: 3.5em;
  width: 100%;
  background-color: rgb(255, 255, 255);
  z-index: 1000;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.8);
}
.nav__top {
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 3.5em;
  z-index: 100;
}
.nav__logo {
  position: absolute;
  margin-left: 1em;
  cursor: pointer;
  z-index: 1000;
}
.nav__text {
  color: #333;
  font-size: 2.5rem;
  text-decoration: none;
  text-transform: uppercase;
}
.nav__text span {
  color: #006be5;
}

.nav-mobile__btn {
  position: absolute;
  right: 0.1em;
  padding: 1em;
  z-index: 1000;
  background: none;
  border: 1px solid transparent;
  cursor: pointer;
}
.nav-mobile__btn span {
  display: block;
  width: 30px;
  height: 3px;
  margin-bottom: 5px;
  border-radius: 2px;
  background-color: #000;
}
.nav-mobile__btn span:last-child {
  margin-bottom: 0;
}
.nav-mobile__btn, .nav-mobile__btn span {
  transition: all 0.4s ease-in-out;
}
.nav-mobile__btn--active, .nav-mobile__btn span--active {
  transition-delay: 0.8s;
  transform: rotate(45deg);
}
.nav-mobile__btn--active span:nth-child(2), .nav-mobile__btn span--active span:nth-child(2) {
  width: 0;
}
.nav-mobile__btn--active span:nth-child(1), .nav-mobile__btn--active span:nth-child(3), .nav-mobile__btn span--active span:nth-child(1), .nav-mobile__btn span--active span:nth-child(3) {
  transition-delay: 0.4s;
}
.nav-mobile__btn--active span:nth-child(1), .nav-mobile__btn span--active span:nth-child(1) {
  transform: translateY(8px);
}
.nav-mobile__btn--active span:nth-child(3), .nav-mobile__btn span--active span:nth-child(3) {
  transform: translateY(-8px) rotate(90deg);
}
.nav-mobile__items {
  position: fixed;
  top: 0;
  display: flex;
  height: 100vh;
  width: 100%;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
  background-color: #fff;
  transform: translateX(100%);
  transition: transform 0.3s;
}
.nav-mobile__items--active {
  transform: translateX(0);
}
.nav-mobile__item {
  display: block;
  margin: 0em 0;
  padding: 0.5em 2px;
  color: #333;
  font-size: 2.8rem;
  text-decoration: none;
  text-transform: capitalize;
  transition: 0.3s;
}
.nav-mobile__item span {
  color: #006be5;
}
.nav-mobile__item:hover {
  color: #000;
  border: none;
  border-radius: 10px;
  padding: 0.5em;
  background-color: #eee;
}

.nav-desktop {
  display: none;
}
.nav-desktop__top {
  margin: 0 auto;
  max-width: 1200px;
}
.nav-desktop__items {
  position: absolute;
  right: 0;
  margin: 0 0.5em;
}
.nav-desktop__item {
  margin: 0.25em;
  padding: 0.5em;
  color: #333;
  font-size: 1.8rem;
  text-decoration: none;
  text-transform: capitalize;
  cursor: pointer;
  transition: 0.3s;
}
.nav-desktop__item span {
  color: #006be5;
}
.nav-desktop__item:hover {
  color: #000;
  border: none;
  border-radius: 10px;
  background-color: #eee;
}
.nav-desktop__active {
  color: #000;
  border: none;
  border-radius: 10px;
  background-color: #eee;
}

.header-mobile {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.header-mobile__img {
  position: relative;
  width: 100%;
  height: 250px;
  object-fit: cover;
  background-position: center;
  background-size: cover;
  background-image: url(/dist/img/moto-passion.jpg);
}
.header-mobile__arrow {
  position: relative;
  display: flex;
  justify-content: center;
  text-decoration: none;
  margin-top: 1em;
  color: #232323;
}

.offers {
  position: relative;
}
.offers__box {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.offers__box--one {
  margin-top: 2em;
}
.offers__img {
  width: 100%;
  margin-bottom: 1em;
  border-radius: 10px;
}
.offers__img--last {
  margin-bottom: 0em;
}

.aboutus__box {
  display: flex;
  flex-direction: column;
}
.aboutus__img--img {
  width: 100%;
  margin-bottom: 1em;
  border-radius: 10px;
}

.current-cars {
  position: relative;
}
.current-cars__box {
  display: flex;
  flex-direction: column;
}
.current-cars__img img {
  width: 100%;
  margin-bottom: 1em;
  border-radius: 10px;
}
.current-cars__text p {
  padding: 0.25em 0;
}
.current-cars__text span {
  color: #006be5;
}
.current-cars__btn {
  margin-top: 1em;
  margin-bottom: 0.5em;
}

.model {
  position: relative;
}
.model__img {
  margin: 1em 0;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  border-radius: 10px;
}
.model__img img {
  margin: 1em 0;
  width: 100%;
  height: auto;
  border-radius: 10px;
}

.brochure {
  position: relative;
  cursor: pointer;
}
.brochure__img {
  width: 100%;
  border-radius: 10px;
  cursor: pointer;
}

.gallery {
  position: relative;
}
.gallery__slider {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.gallery__box {
  position: relative;
  display: flex;
  height: 100%;
  transform: translateX(0);
  transition: transform 0.5s;
}
.gallery__img {
  position: relative;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
  background-position: center;
  background-size: cover;
}
.gallery__arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  font-size: 2em;
  padding: 0.25em 0.5em;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.5);
  border: none;
  cursor: pointer;
  z-index: 500;
}
.gallery__arrow--next {
  right: 0;
}
.gallery__arrow--prev {
  left: 0;
}
.gallery__arrow i {
  display: block;
  transition: transform 0.3s;
}
.gallery__arrow:hover i {
  transform: scale(0.8);
}

.video {
  position: relative;
}
.video__box {
  display: flex;
  height: 100%;
  width: 100%;
}
.video__item video {
  width: 100%;
  border-radius: 10px;
}

.video-youtube {
  position: relative;
  display: flex;
}
.video-youtube__item {
  width: 100%;
}
.video-youtube__item iframe {
  width: 100%;
  height: 29vh;
  border-radius: 10px;
}

.contact__box {
  position: relative;
  display: flex;
  flex-direction: column;
}
.contact__item {
  margin-top: 2em;
  text-align: center;
  border: 1px solid transparent;
  border-radius: 8px;
}
.contact__icon {
  width: 2.8em;
}
.contact__title {
  margin-top: 0.5em;
  font-size: 2.2rem;
  font-weight: lighter;
}
.contact__title span {
  color: #006be5;
  font-weight: normal;
}
.contact__text a {
  text-decoration: none;
  color: black;
  transition: 0.3s;
  cursor: pointer;
}
.contact__text a:hover {
  color: #006be5;
}
.contact__text p {
  margin: 0.5em;
}

.social-media__box {
  position: relative;
  display: flex;
  justify-content: center;
}
.social-media__item {
  margin: 2em 1em 0 1em;
}
.social-media__icon {
  width: 2.8em;
  transition: transform 0.3s;
  cursor: pointer;
}
.social-media__icon:hover {
  transform: scale(1.1);
}

.footer {
  color: #fff;
  background-color: #006be5;
}
.footer__text {
  padding: 2rem;
  text-align: center;
}

@media (min-width: 768px) {
  .section-padding {
    padding: 2em;
  }
  .section-heading {
    font-size: 2.8rem;
  }
  .section-title {
    font-size: 2.5rem;
  }
  .section-title--small {
    font-size: 2.2rem;
  }
  .section-text {
    font-size: 1.8rem;
  }
  .section-text--phone {
    font-size: 2rem;
    margin-top: 1em;
  }
  .nav-mobile__btn {
    right: 1em;
  }
  .nav-mobile__btn {
    margin-right: 0.5em;
  }
  .header-mobile__title {
    font-size: 3.2rem;
  }
  .header-mobile__img {
    height: 350px;
  }
  .arrow {
    font-size: 2em;
  }
  .btn {
    font-size: 1.8rem;
  }
  .offers__box {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: 0em;
  }
  .offers__box-item {
    width: 49.5%;
  }
  .offers__box-item--one {
    margin-bottom: 1em;
  }
  .offers__img {
    margin-bottom: 0em;
  }
  .aboutus__box {
    flex-direction: row;
    justify-content: space-between;
  }
  .aboutus__text {
    font-size: 1.8rem;
    width: 45%;
  }
  .aboutus__text--right {
    text-align: right;
  }
  .aboutus__img {
    width: 50%;
    object-position: center;
  }
  .aboutus__img--right {
    order: 1;
  }
  .aboutus__img:first-child {
    margin-top: 0.75em;
  }
  .aboutus__img-two {
    width: 49%;
    object-position: center;
  }
  .current-cars__box {
    flex-direction: row;
    justify-content: space-between;
  }
  .current-cars__text {
    font-size: 1.8rem;
    width: 45%;
  }
  .current-cars__text--right {
    text-align: right;
  }
  .current-cars__img {
    width: 50%;
    object-position: center;
  }
  .current-cars__img--right {
    order: 1;
  }
  .current-cars__img:first-child {
    margin-top: 0.5em;
  }
  .current-cars__btn {
    font-size: 1.8rem;
  }
  .current-cars__btn--btn {
    width: 50%;
    left: 50%;
    transform: translate(-50%);
  }
  .card__box {
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    padding-bottom: 1em;
  }
  .card__item {
    width: 30%;
    height: 400px;
    margin-bottom: 0;
  }
  .video-youtube__item iframe {
    height: 56vh;
  }
  .contact__box {
    flex-direction: row;
    justify-content: space-between;
    margin-top: 2em;
  }
  .contact__title {
    margin: 1em 0 0.5em 0;
  }
}
@media (min-width: 992px) {
  .section-heading {
    font-size: 3rem;
  }
  .section-title {
    font-size: 2.8rem;
  }
  .section-title--small {
    font-size: 2.5rem;
  }
  .section-header__title {
    font-size: 3.5rem;
  }
  .section-text {
    font-size: 2rem;
  }
  .section-text--phone {
    font-size: 2.2rem;
  }
  .nav-mobile {
    display: none;
  }
  .nav-desktop {
    display: flex;
  }
  .header-mobile__img {
    height: 500px;
  }
  .btn {
    font-size: 2rem;
  }
  .card__item {
    height: 500px;
  }
  .model__img {
    align-items: center;
  }
  .model__img img {
    width: 80%;
  }
  .video-youtube__item iframe {
    height: 76.5vh;
  }
  .contact__box {
    justify-content: space-around;
  }
  .contact__item {
    width: 500px;
  }
}
@media (min-width: 1200px) {
  .header-mobile__img {
    height: 600px;
  }
}