@import './colors';

*,
*::after,
*::before {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
}

html {
	font-size: 62.5%;
	scroll-behavior: smooth;
}

body {
	font-family: 'Open Sans', sans-serif;
	font-size: 1.6rem;
	background-color: #fff;
}

[id] {
	scroll-margin-top: 3em;
}

.wrapper {
	margin: 0 auto;
	max-width: 1200px;
	width: 100%;
}

.section-padding {
	padding: 2em 1em;
}

.section-padding-hero {
	padding: 2em 0em;
}

.section-margin {
	&__top {
		margin-top: 2em;
	}

	&__bottom {
		margin-bottom: 1em;
	}
}

.section-heading {
	position: relative;
	padding-left: 0.5em;
	font-size: 2.5rem;
	font-weight: lighter;
	text-transform: uppercase;
	text-align: left;
	letter-spacing: 2px;

	span {
		color: $light-blue;
		font-weight: normal;
	}

	&::before {
		position: absolute;
		width: 5px;
		height: 100%;
		top: 50%;
		left: 0;
		transform: translate(0, -50%);
		background-color: $light-blue;
		content: '';
	}
}

.section-title {
	margin: 1em 0;
	font-size: 2.3rem;
	font-weight: lighter;

	span {
		color: $light-blue;
		font-weight: normal;
	}

	&--small {
		font-size: 2rem;
	}
}

.section-header {
	&__padding {
		padding: 2em 1em 0em 1em;
	}

	&__title {
		position: relative;
		width: 100%;
		height: 100%;
		text-align: center;
		font-size: 3rem;
		font-weight: lighter;
		z-index: 800;

		span {
			color: $light-blue;
			font-weight: normal;
		}
	}
}

.section-text {
	font-size: 1.6rem;
	padding: 0.5em 0;

	span {
		color: $light-blue;
		font-weight: 600;
	}

	&--phone {
		font-size: 1.8rem;
	}
}

.arrow {
	padding: 0.2em 0.5em;
	font-size: 1.8em;
	border: none;
	cursor: pointer;
}

.btn {
	display: flex;
	justify-content: center;
	padding: 0.5em;
	background-color: $white;
	border: 1px solid $light-blue;
	border-radius: 10px;
	text-decoration: none;
	font-weight: normal;
	font-size: 1.6rem;
	color: $dark;
	cursor: pointer;

	span {
		color: $light-blue;
		margin-left: 0.3em;
	}

	&::before {
		background-color: $light-white;
	}

	&__animation {
		position: relative;
		transition: background-color 0.3s;
		overflow: hidden;
		z-index: 0;

		&::before {
			display: block;
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			transform: scaleX(0);
			transform-origin: right;
			transition: transform 0.3s;
			z-index: -1;
		}

		&:hover::before {
			transform: scaleX(1);
			transform-origin: left;
		}
	}
}

.slider {
	position: relative;
	width: 100%;
	height: 100%;
	overflow: hidden;

	&__box {
		position: relative;
		display: flex;
		height: 100%;
	}
}

// NAV

.nav {
	// position: fixed;
	position: sticky;
	top: 0;
	height: 3.5em;
	width: 100%;
	background-color: rgb(255, 255, 255);
	z-index: 1000;
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.8);

	&__top {
		position: relative;
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: space-between;
		height: 3.5em;
		z-index: 100;
	}

	&__logo {
		position: absolute;
		margin-left: 1em;
		cursor: pointer;
		z-index: 1000;
	}

	&__text{
		color: #333;
		font-size: 2.5rem;
		text-decoration: none;
		text-transform: uppercase;

		span {
			color: $light-blue;
		}
	}
}

// NAV MOBILE

.nav-mobile {
	&__btn {
		position: absolute;
		right: 0.1em;
		padding: 1em;
		z-index: 1000;
		background: none;
		border: 1px solid transparent;
		cursor: pointer;

		span {
			display: block;
			width: 30px;
			height: 3px;
			margin-bottom: 5px;
			border-radius: 2px;
			background-color: #000;

			&:last-child {
				margin-bottom: 0;
			}
		}
	}

	&__btn,
	&__btn span {
		transition: all 0.4s ease-in-out;

		&--active {
			transition-delay: 0.8s;
			transform: rotate(45deg);
		}

		&--active span:nth-child(2) {
			width: 0;
		}

		&--active span:nth-child(1),
		&--active span:nth-child(3) {
			transition-delay: 0.4s;
		}

		&--active span:nth-child(1) {
			transform: translateY(8px);
		}

		&--active span:nth-child(3) {
			transform: translateY(-8px) rotate(90deg);
		}
	}

	&__items {
		position: fixed;
		top: 0;
		display: flex;
		height: 100vh;
		width: 100%;
		justify-content: center;
		align-items: center;
		text-align: center;
		flex-direction: column;
		background-color: #fff;
		transform: translateX(100%);
		transition: transform 0.3s;

		&--active {
			transform: translateX(0);
		}
	}

	&__item {
		display: block;
		margin: 0em 0;
		padding: 0.5em 2px;
		color: #333;
		font-size: 2.8rem;
		text-decoration: none;
		text-transform: capitalize;
		transition: 0.3s;

		span {
			color: $light-blue;
		}

		&:hover {
			color: #000;
			border: none;
			border-radius: 10px;
			padding: 0.5em;
			background-color: #eee;
		}
	}
}

// NAV DESKTOP

.nav-desktop {
	display: none;
	// position: sticky;
	// top: 0;

	&__top {
		margin: 0 auto;
		max-width: 1200px;
	}

	&__items {
		position: absolute;
		right: 0;
		margin: 0 0.5em;
	}

	&__item {
		margin: 0.25em;
		padding: 0.5em;
		color: #333;
		font-size: 1.8rem;
		text-decoration: none;
		text-transform: capitalize;
		cursor: pointer;
		transition: 0.3s;

		span {
			color: $light-blue;
		}

		&:hover {
			color: #000;
			border: none;
			border-radius: 10px;
			background-color: #eee;
		}
	}

	&__active {
		color: #000;
		border: none;
		border-radius: 10px;
		background-color: #eee;
	}
}

// HEADER MOBILE

.header-mobile {
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;

	&__img {
		position: relative;
		width: 100%;
		height: 250px;
		object-fit: cover;
		background-position: center;
		background-size: cover;
		background-image: url(/dist/img/moto-passion.jpg);
	}

	&__arrow {
		position: relative;
		display: flex;
		justify-content: center;
		text-decoration: none;
		margin-top: 1em;
		color: $dark;
	}
}

// HEADER DESKTOP

.offers {
	position: relative;

	&__box {
		display: flex;
		flex-direction: column;
		height: 100%;

		&--one {
			margin-top: 2em;
		}
	}

	&__img {
		width: 100%;
		margin-bottom: 1em;
		border-radius: 10px;

		&--last {
			margin-bottom: 0em;
		}
	}
}

.aboutus {
	&__box {
		display: flex;
		flex-direction: column;
	}

	&__img {
		&--img {
			width: 100%;
			margin-bottom: 1em;
			border-radius: 10px;
		}
	}
}

.current-cars {
	position: relative;

	&__box {
		display: flex;
		flex-direction: column;
	}

	&__img img {
		width: 100%;
		margin-bottom: 1em;
		border-radius: 10px;
	}

	&__text {
		p {
			padding: 0.25em 0;
		}

		span {
			color: $light-blue;
		}
	}

	&__btn {
		// bottom: 1em;
		margin-top: 1em;
		margin-bottom: 0.5em;
	}
}

.model {
	position: relative;

	&__img {
		margin: 1em 0;
		display: flex;
		flex-direction: column;
		width: 100%;
		height: auto;
		border-radius: 10px;
	}

	&__img img {
		margin: 1em 0;
		width: 100%;
		height: auto;
		border-radius: 10px;
	}
}

.brochure {
	position: relative;
	cursor: pointer;

	&__img {
		width: 100%;
		border-radius: 10px;
		cursor: pointer;
	}
}

.gallery {
	position: relative;

	&__slider {
		position: relative;
		width: 100%;
		height: 100%;
		overflow: hidden;
	}

	&__box {
		position: relative;
		display: flex;
		height: 100%;
		transform: translateX(0);
		transition: transform 0.5s;
	}

	&__img {
		position: relative;
		width: 100%;
		height: 100%;
		object-fit: cover;
		border-radius: 10px;
		background-position: center;
		background-size: cover;
	}

	&__arrow {
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		font-size: 2em;
		padding: 0.25em 0.5em;
		color: $white;
		background-color: rgba(0, 0, 0, 0.5);
		border: none;
		cursor: pointer;
		z-index: 500;

		&--next {
			right: 0;
		}

		&--prev {
			left: 0;
		}

		i {
			display: block;
			transition: transform 0.3s;
		}

		&:hover i {
			transform: scale(0.8);
		}
	}
}

.video {
	position: relative;

	&__box {
		display: flex;
		height: 100%;
		width: 100%;
	}

	&__item video {
		width: 100%;
		border-radius: 10px;
	}
}

.video-youtube {
	position: relative;
	display: flex;

	&__item {
		width: 100%;
	}

	&__item iframe {
		width: 100%;
		height: 29vh;
		border-radius: 10px;
	}
}

.contact {
	&__box {
		position: relative;
		display: flex;
		flex-direction: column;
	}
	&__item {
		margin-top: 2em;
		text-align: center;
		border: 1px solid transparent;
		border-radius: 8px;
	}

	&__icon {
		// margin: 1em;
		width: 2.8em;
	}

	&__title {
		margin-top: 0.5em;
		font-size: 2.2rem;
		font-weight: lighter;

		span {
			color: $light-blue;
			font-weight: normal;
		}
	}

	&__text a {
		// font-weight: bold;
		text-decoration: none;
		color: black;
		transition: 0.3s;
		cursor: pointer;

		&:hover {
			color: $light-blue;
		}
	}

	&__text p {
		margin: 0.5em;
	}
}

.social-media {
	&__box {
		position: relative;
		display: flex;
		justify-content: center;
	}

	&__item {
		margin: 2em 1em 0 1em;
	}

	&__icon {
		width: 2.8em;
		transition: transform 0.3s;
		cursor: pointer;

		&:hover {
			transform: scale(1.1);
		}
	}
}

.footer {
	color: $white;
	background-color: $light-blue;

	&__text {
		padding: 2rem;
		text-align: center;
	}
}

// @import './small';
@import './medium';
@import './large';
