@media (min-width: 992px) {
	.section-heading {
		font-size: 3rem;
	}

	.section-title {
		font-size: 2.8rem;

		&--small {
			font-size: 2.5rem;
		}
	}

	.section-header {
		&__title {
			font-size: 3.5rem;
		}
	}

	.section-text {
		font-size: 2rem;

		&--phone {
			font-size: 2.2rem;
		}
	}

	.nav-mobile {
		display: none;
	}

	.nav-desktop {
		display: flex;
	}

	.header-mobile {
		&__img {
			height: 500px;
		}
	}

	.btn {
		font-size: 2rem;
	}



	.card {
		&__item {
			height: 500px;
		}
	}

	.model {
		&__img {
			align-items: center;
		}

		&__img img {
			width: 80%;
		}
	}

	// .gallery {
	// 	&__img {
	// 		height: 80vh;
	// 	}
	// }

	.video-youtube {
		&__item iframe {
			height: 76.5vh;
		}
	}

	.contact {
		&__box {
			justify-content: space-around;
		}

		&__item {
			width: 500px;
		}
	}
}

@media (min-width: 1200px) {
	.header-mobile {
		&__img {
			height: 600px;
		}
	}
}
