@media (min-width: 768px) {
	.section-padding {
		padding: 2em;
	}

	.section-heading {
		font-size: 2.8rem;
	}

	.section-title {
		font-size: 2.5rem;

		&--small {
			font-size: 2.2rem;
		}
	}

	.section-text {
		font-size: 1.8rem;

		&--phone {
			font-size: 2rem;
			margin-top: 1em;
		}
	}

	.nav-mobile {
		&__btn {
			right: 1em;
		}
	}

	.nav-mobile {
		&__btn {
			margin-right: 0.5em;
		}
	}

	.header-mobile__title {
		font-size: 3.2rem;
	}

	.header-mobile {
		&__img {
			height: 350px;
		}
	}

	.arrow {
		font-size: 2em;
	}

	.btn {
		font-size: 1.8rem;
	}

	.offers {
		&__box {
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			flex-wrap: wrap;
			margin-bottom: 0em;
		}

		&__box-item {
			width: 49.5%;

			&--one {
				margin-bottom: 1em;
			}
		}

		&__img {
			margin-bottom: 0em;
		}
	}

	.aboutus {
		&__box {
			flex-direction: row;
			justify-content: space-between;
		}

		&__text {
			font-size: 1.8rem;
			width: 45%;

			&--right {
				text-align: right;
			}
		}

		&__img {
			width: 50%;
			object-position: center;

			&--right {
				order: 1;
			}

			&:first-child {
				margin-top: 0.75em;
			}
		}

		&__img-two {
			width: 49%;
			object-position: center;
		}
	}

	.current-cars {
		&__box {
			flex-direction: row;
			justify-content: space-between;
		}

		&__text {
			font-size: 1.8rem;
			width: 45%;

			&--right {
				text-align: right;
			}
		}

		&__img {
			width: 50%;
			object-position: center;

			&--right {
				order: 1;
			}

			&:first-child {
				margin-top: 0.5em;
			}
		}

		&__btn {
			font-size: 1.8rem;

			&--btn {
				width: 50%;
				left: 50%;
				transform: translate(-50%);
			}
		}
	}

	.card {
		&__box {
			flex-direction: row;
			justify-content: space-between;
			flex-wrap: wrap;
			padding-bottom: 1em;
		}

		&__item {
			width: 30%;
			height: 400px;
			margin-bottom: 0;
		}
	}

	.video-youtube {
		&__item iframe {
			height: 56vh;
		}
	}

	.contact {
		&__box {
			flex-direction: row;
			justify-content: space-between;
			margin-top: 2em;
		}

		&__title {
			margin: 1em 0 0.5em 0;
		}
	}
}
